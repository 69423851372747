import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, of} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConnectivityService {

  public appIsOnline$: Observable<boolean>;
  public networkdatartt: any
  public networkdatadownlink: any
  public networkdataeffect: any
  public newNavigate: any

  constructor() {

    this.initConnectivityMonitoring();

  }

  private initConnectivityMonitoring() {

    if (!window || !navigator || !('onLine' in navigator)) return;
    this.newNavigate = navigator
    this.networkdataeffect = this.newNavigate.connection.effectiveType
    this.networkdatadownlink = this.newNavigate.connection.downlink
    this.networkdatartt = this.newNavigate.connection.rtt


    // console.log(navigator)


    this.appIsOnline$ = merge(of(null),fromEvent(window, "online"),fromEvent(window, "offline")).pipe(map(() => navigator.onLine))

  }

}